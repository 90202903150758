export const ENGLISH = {
    header: {
        accountSetting: 'Account Setting',
        accountSettings: 'Account settings',
        allCards: 'All cards',
        newOrders: 'New orders',
        signedInAs: 'Signed in as',
        signOut: 'Sign out',
        visitWebsite: 'Visit website',
    },
    common: {
        about: 'About',
        appearance: 'Appearance',
        allCards: 'All cards',
        cancel: 'Cancel',
        cardViews: 'Card views',
        changePassword: 'Change Password',
        changesSaved: 'Saved',
        close: 'Close',
        color: 'Color',
        companyLogo: 'Company Logo',
        contact: 'Contact',
        contacts: 'Contacts',
        contactSaved: 'Contact saved',
        copy: 'Copy',
        copied: 'Copied!',
        createOrder: 'Create order',
        currentPassword: 'Current Password',
        dataAndPrivacy: 'Data and Privacy',
        deleteCard: 'Delete card',
        deletePermanently: 'Delete permanently',
        disable: 'Disable',
        dismiss: 'Dismiss',
        download: 'Download',
        edit: 'Edit',
        editCard: 'Edit card',
        email: 'Email',
        emailDash: 'E-mail',
        emailAddress: 'Email address',
        enable: 'Enable',
        facebook: 'Facebook',
        general: 'General',
        instagram: 'Instagram',
        language: 'Language',
        leave: 'Leave',
        links: 'Links',
        linkedin: 'Linkedin',
        message: 'Message',
        name: 'Name',
        newPassword: 'New Password',
        noFileChosen: 'No file chosen',
        notifications: 'Notifications',
        others: 'Others',
        password: 'Password',
        personalized: 'Personalized',
        phone: 'Phone',
        previewCard: 'AndroidPreview card',
        proceed: 'Proceed',
        pleaseSelectOption : 'Please select an option',
        qrCode: 'QR code',
        refreshPage : 'Please refresh the page to continue',
        reload: 'Reload',
        remove: 'Remove',
        save: 'Save',
        saveChanges: 'Save changes',
        saveContact: 'Save Contact',
        search: 'Search',
        selectOption: 'Select option',
        selectPlatform: 'Select platform',
        send: 'Send',
        sent: 'Sent',
        sessionExpired: 'Session Expired',
        setInactive: 'Set inactive',
        share: 'Share',
        shareCard: 'Share card',
        stay: 'Stay',
        subject: 'Subject',
        subjectLine: 'Subject line',
        support: 'Support',
        today: 'Today',
        tiktok: 'Tiktok',
        title: 'Title',
        to: 'to',
        quantity: 'Quantity',
        upload: 'Upload',
        uploadLogo: 'Upload Logo',
        updateWatermark: 'Update Watermark',
        yourAccount: 'Your Account',
        website: 'Website',
        whatsapp: 'WhatsApp',
        x: 'X',
        yourBusinessCard: 'Your business card'
    },
    labels: {
        cardProduct: 'Card product',
        choose: 'Choose',
        downloadIcardsTemplate: 'Download iCards template',
        enterUuid: 'Enter UUID',
        position: 'Position',
        qrCodeSize: 'QR size',
        searchByOrderIdOrCardHolderBuyer: 'Search by Order ID or Card holder/buyer',
        uploadIcardsTemplate: 'Upload iCards template'
    },
    desc: {
        updateWatermark: 'Update the watermark for card profiles owned by a user, based on their email.',
    },
    message: {
        watermarkChanges: 'The watermark has been successfully changed.',
    },
    modal: {
        areYouSure: 'Are you sure?',
        assignProductText: 'Product type {{product}} will be assigned to CARD ID {{cardId}}',
        assignUuidText: 'UUID {{uuid}} will be assigned to Payment ID {{paymentId}}',
        processTemplate: 'The Excel {{filename} file will be processed, and card profiles will be updated according to the Profile ID field in the template.'
    },
    qrCode: {
        uuidGeneratorDesc: 'By pressing the button, NEW UUIDs and Card profiles will be generated (stored in the database) and you will get a zip file of the QR code images.',
        uuidGeneratorWarningDesc: 'USE this section ONLY if you want to print generated QR code images onto physical cards!',
        uuidGeneratorSuccessDesc: 'The NEW QR code images and Card profiles are generated!',
        uuidBatchGeneratorDesc: 'Use this section to generate QR code images for the EXISTING UUIDs, note that this section will not create a new UUIDs (will not store it in the database)'
    }
};